import { render, staticRenderFns } from "./PayPC.vue?vue&type=template&id=f71a5c8c&scoped=true&"
import script from "./PayPC.vue?vue&type=script&lang=js&"
export * from "./PayPC.vue?vue&type=script&lang=js&"
import style0 from "./PayPC.vue?vue&type=style&index=0&id=f71a5c8c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f71a5c8c",
  null
  
)

export default component.exports